<template>
  <b-row>
    <b-col cols="12" xl="12" lg="12" md="12">
      <crud-button
        :showAddNew="false"
        :showRefresh="true"
        :onClickList="fetchUruns"
        :onClickRefresh="refreshUrunsFromApi"
      />
      <urun-filter />
    </b-col>
    <b-col cols="12" xl="12" lg="12" md="12">
      <b-card v-if="getUrunList" no-body class="card-company-table">
        <b-table
          :items="getUrunList"
          responsive
          :fields="tableColumns"
          class="mb-0"
          show-empty
          empty-text="Hiçbir eşleşen kayıt bulunamadı"
        >
          <!-- category -->
          <template #cell(urunGrup_Id)="data">
            <div class="d-flex align-items-center">
              <span>{{ resolveUrunGrup(data.item.urunGrup_Id) }}</span>
            </div>
          </template>

          <template #cell(isActive)="data">
            <b-form-checkbox
              :checked="data.item.dbIsActive"
              class="custom-control-success"
              name="check-button"
              @change="deactivate(data.item.id, 'db')"
            >
              <b-avatar
                size="18"
                class="mr-1"
                :variant="resolveStatusVariant(data.item.dbIsActive)"
                rounded
              >
                <feather-icon icon="DatabaseIcon" size="18" /> </b-avatar
            ></b-form-checkbox>

            <b-form-checkbox
              :checked="data.item.isShowInWebSite"
              class="custom-control-success"
              name="check-button"
              @change="deactivate(data.item.id, 'website')"
              ><b-avatar
                size="18"
                class="mr-1"
                :variant="resolveStatusVariant(data.item.isShowInWebSite)"
                rounded
              >
                <feather-icon icon="AirplayIcon" size="18" />
              </b-avatar>
            </b-form-checkbox>

            <b-form-checkbox
              :checked="data.item.isActive"
              class="custom-control-success"
              name="check-button"
              @change="deactivate(data.item.id, 'self')"
              ><b-avatar
                size="18"
                :variant="resolveStatusVariant(data.item.isActive)"
                rounded
              >
                <feather-icon icon="CheckIcon" size="18" />
              </b-avatar>
            </b-form-checkbox>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <!-- Update -->
              <feather-icon
                :id="`user-row-${data.item.id}-edit-icon`"
                @click="
                  $router.push({
                    name: 'manage-urun-detay',
                    params: { id: data.item.id },
                  })
                "
                icon="EditIcon"
                class="cursor-pointer text-primary"
                size="16"
              />

              <!-- Remove -->
              <feather-icon
                :id="`user-row-${data.item.id}-remove-icon`"
                icon="Trash2Icon"
                class="cursor-pointer mx-1 text-danger"
                size="16"
              />
            </div>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
// Buttons
import AlertService from '@/common/alert.service'
import CrudButton from '@/components/CrudButton.vue'
import useUrun from './useUrun';
// Alert

import UrunFilter from './UrunFilter.vue'

export default {
  components: {
    // Local Components
    UrunFilter,
    CrudButton,
  },
  setup() {
    const {
      fetchUruns,
      getUrunList,
      tableColumns,
      deactivate,
      resolveUrunGrup,
      resolveStatusVariant,
    } = useUrun();

    return {
      fetchUruns,
      getUrunList,
      tableColumns,
      deactivate,
      resolveUrunGrup,
      resolveStatusVariant,
    };
  },

  methods: {
    refreshUrunsFromApi() {
      this.$store.dispatch('refreshUrunsFromApi')
        .then(response => {
          if (response.statusCode === 200) {
            AlertService.success(this, response.message)
            this.fetchUruns();
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
  },
}
</script>

<style>
</style>
