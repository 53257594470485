import { computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default function useUrun() {
  // Table Handlers
  const tableColumns = [
    { label: 'Kod', key: 'sUrunKodu' },
    { label: 'Ad', key: 'sUrunAdi' },
    { label: 'Grup', key: 'urunGrup_Id' },
    { label: 'Aktif Durum', key: 'isActive' },
    { label: 'Aksiyonlar', key: 'actions', class: 'text-right' },
  ]

  const fetchUruns = () => {
    store.dispatch('fetchUruns', { })
  }

  const getUrunList = computed(() => {
    const urungrups = store.getters.getUruns
    return urungrups
  })

  onMounted(fetchUruns)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUrunGrup = urunGrupId => {
    const urunGrup = store.getters.getSelectionUrunGrups.find(item => item.value === urunGrupId)
    if (urunGrup) {
      return urunGrup.label
    }
    return ''
  }

  const resolveStatusVariant = status => { 
    if (status) return 'light-success'
    return 'light-danger';
  }

  function deactivate(id, type) {
    const request = { id, type };
    store.dispatch('toggleActiveUrun', request)
      .then(response => {
        if (response.statusCode === 200) {
          this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: response.message },
          });
          fetchUruns();
        } else {
          AlertService.error(this, response.message);
        }
      })
  }

  return {
    fetchUruns,
    getUrunList,
    tableColumns,
    deactivate,
    resolveUrunGrup,
    resolveStatusVariant,

  }
}
