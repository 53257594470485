<template>
  <b-card>
    <b-row>

      <b-col cols="12" md="3"
        class="mb-md-0 mb-2">
        <b-form-group>
          <urun-grup-selection :grupSelect.sync="urunGrupIdFilter" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3"
        class="mb-md-0 mb-2">
        <b-form-group>
          <v-select
            v-model="isActiveFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="urunDurumOptions"
            class="w-100"
            :reduce="val => val.value"
            :placeholder="$t('Ürün Durumu')" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3"
        class="mb-md-0 mb-2">
        <b-form-group>
          <v-select
            v-model="dBIsActiveFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="urunDbDurumOptions"
            class="w-100"
            :reduce="val => val.value"
            :placeholder="$t('Veri Tabanı Durumu')" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3"
        class="mb-md-0 mb-2">
        <b-form-group>
          <v-select
            v-model="isShowInWebSiteFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="urunShowWebSiteDurumOptions"
            class="w-100"
            :reduce="val => val.value"
            :placeholder="$t('Website Durumu')" />
        </b-form-group>
      </b-col>

      <!-- Arama -->
      <b-col md="3">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="queryFilter" :placeholder="$t('Ara...')" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

// Options
import StaticOptions from '@/common/options/StaticOptions'

import UrunGrupSelection from '../selection/UrunGrupSelection.vue';

export default {
  components: {
    
    UrunGrupSelection,
  },
  data() {
    return {
      urunDurumOptions: StaticOptions.urunDurumOptions,
      urunDbDurumOptions: StaticOptions.urunDbDurumOptions,
      urunShowWebSiteDurumOptions: StaticOptions.urunShowWebSiteDurumOptions,
    };
  },
  computed: {
    urunGrupIdFilter: {
      get() {
        return this.$store.getters.getUrunLocalFilters.urunGrupId
      },
      set(value) {
        this.$store.commit('setUrunFilter', { key: 'urunGrupId', value })
      },
    },
    isActiveFilter: {
      get() {
        return this.$store.getters.getUrunLocalFilters.isActive
      },
      set(value) {
        this.$store.commit('setUrunFilter', { key: 'isActive', value })
      },
    },
    dBIsActiveFilter: {
      get() {
        return this.$store.getters.getUrunLocalFilters.dBIsActive
      },
      set(value) {
        this.$store.commit('setUrunFilter', { key: 'dBIsActive', value })
      },
    },
    isShowInWebSiteFilter: {
      get() {
        return this.$store.getters.getUrunLocalFilters.isShowInWebSite
      },
      set(value) {
        this.$store.commit('setUrunFilter', { key: 'isShowInWebSite', value })
      },
    },
    queryFilter: {
      get() {
        return this.$store.getters.getUrunLocalFilters.query
      },
      set(value) {
        this.$store.commit('setUrunFilter', { key: 'query', value })
      },
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
